<template>
  <div v-if="supply && supply.tariff">
    <pre>
      {{ JSON.stringify(supply.tariff, null, 4)   }}
    </pre>
  </div>
</template>

<script>
export default {
  name: "DetailTariff",
    data: () => {
        return {
            supply: null
        };
    },
    created() {
        this.axios.get("/api/admin/supply").then(resp => {
            this.supply = resp.data;
        });
    },
  }
</script>
